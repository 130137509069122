import React from 'react'
import './Footer.css';

function Footer() {

  return (
    <div className='footer-container'>
      <h1>
        <div className='footer-item-wrapper'>
          
            <div className='footer-item'>
              <a href="mailto:info@cbaalarm.com">info@cbaalarm.com</a>
            </div>
            <div className='footer-item'>
            <a href='https://www.facebook.com/%E6%B0%B8%E5%AE%89%E9%98%B2%E7%9B%9C%E9%9B%BB%E5%8A%9B%E5%B7%A5%E7%A8%8B-CBA-Electrical-Security-Systems-Ltd-103710531938802' target="_blank noreferrer"><i className='fab fa-facebook' /></a>
            </div>
        </div>
        <div className='footer-item-wrapper'>
          9026 Oak St, Vancouver, BC V6P 4B9
        </div>
        <div className='footer-item-wrapper'>
            &copy; {(new Date().getFullYear())} CBA Alarm. All rights reserved.
        </div>
      </h1>
    </div>
  );
}

export default Footer
