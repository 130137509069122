import React from 'react';
import '../../App.css';
import Showcase from '../Showcase';

function Home(){

    return(
        <>
            <Showcase/>
        </>
    )
}

export default Home;