import React from 'react';
import Cards from '../Cards';
import { useTranslation } from 'react-i18next';
import '../../App.css';
import './Products-And-Services.css';

export default function ProductsAndServices(){
    const { t } = useTranslation();
    return (
        <div>
            <h1 className='products-and-services'>{t("Products & Services")}</h1>
            <Cards/>
            <div className='system-guides'>
                <h2>
                    {t("System Guides")}:<br/>
                    <a href='./documents/DSC Eng.pdf' target='_blank' rel='noopener noreferrer'>DSC System Guide</a><br/>
                    <a href='./documents/DSC Chi-S.pdf' target='_blank' rel='noopener noreferrer'>DSC 系 统 说 明（简 体）</a><br/>
                    <a href='./documents/DSC Chi-T.pdf' target='_blank' rel='noopener noreferrer'>DSC 系 統 說 明（繁 体)</a><br/>
                    <a href='./documents/Honeywell Eng.pdf' target='_blank' rel='noopener noreferrer'>Honeywell or Ademco System Guide</a><br/>
                    <a href='./documents/Honeywell Chi-S.pdf' target='_blank' rel='noopener noreferrer'>Honeywell 或 Ademco 系 统 说 明（简 体）</a><br/>
                    <a href='./documents/Honeywell Chi-T.pdf' target='_blank' rel='noopener noreferrer'>Honeywell 或 Ademco 系 統 說 明（繁 体)</a><br/>
                </h2>
            </div>
        </div>
        
    );
}