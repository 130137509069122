import React from 'react';
import '../../App.css';
import './ContactUs.css';
import { useTranslation } from 'react-i18next';
import VisaLogo from '../../images/visa-logo.png';
import MastercardLogo from '../../images/mastercard-logo.png';
import eTransferLogo from '../../images/e-transfer-logo.png';
import AliPayLogo from '../../images/alipay-logo.png';
import WeChatPay from '../../images/wechat-pay.png';

export default function ContactUs(){
    const {t} = useTranslation();
    return (
        <>
            <h1 className='contact-us'>{t("Contact Us")}</h1>
            <div className='basic-info'>
                <div className="map-container">
                    <div className='map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.8697608046814!2d-123.13130168408836!3d49.20303018433181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548674e8d2529671%3A0x5f7a313f7b8b69a2!2sCBA%20Electrical%20%26%20Security%20Systems%20Ltd.!5e0!3m2!1sen!2sca!4v1614640085041!5m2!1sen!2sca" width="600px" height="450px" style={{border:0}} allowFullScreen="" loading="lazy" title="CBA Alarm Map"></iframe>
                    </div>
                </div>
                <div className="contact-info">
                    <h4>{t("CBA Company Name")}</h4><br/>
                    <p><strong>{t("Address")}:</strong><br/>9026 Oak St, Vancouver, BC V6P 4B9<br/><br/>
                        {/* <strong>{t("Office Hours")}:</strong><br/>{t("Office Hours Monday to Friday")}<br/>{t("Office Hours Saturday")}<br/><br/> */}
                        <strong>{t("Phone")}:</strong><br/> <a href="tel:6042631882">(604) 263-1882</a><br/><a href="tel:6042631882">(604) 880-7888</a><br/>
                        <br/>
                        <strong>{t("Email")}:</strong><br/> <a href="mailto:info@cbaalarm.com">info@cbaalarm.com</a><br/>
                        <br/>
                        <strong><a href='https://www.facebook.com/%E6%B0%B8%E5%AE%89%E9%98%B2%E7%9B%9C%E9%9B%BB%E5%8A%9B%E5%B7%A5%E7%A8%8B-CBA-Electrical-Security-Systems-Ltd-103710531938802' target="_blank noreferrer">Facebook</a></strong><br/>
                        <br/>
                        <strong>{t("Fax")}:</strong><br/> <a href="tel:6042630887">(604) 263-0887</a><br/>
                        <br/>
                        <strong><a href='./documents/CBA-Information-Change.pdf' target='_blank' rel='noopener noreferrer'>{t("CBA Information Change Form")}</a></strong>
                    </p>
                </div>
            </div>
            {/* 
            <h2 style={{textAlign: "center"}}>Accepted payment methods:</h2>
            */}
            <div className='payment-methods-container'>
                <div className='payment-method'>
                    <img src={VisaLogo} alt='Visa' className='payment-icon'/>
                </div>
                <div className='payment-method'>
                    <img src={MastercardLogo} alt='Mastercard' className='payment-icon'/>
                </div>
                <div className='payment-method'>
                    <img src={eTransferLogo} alt='Interac e-Transfer' className='payment-icon'/>
                </div>
                <div className='payment-method'>
                    <img src={AliPayLogo} alt='AliPay' className='payment-icon'/>
                </div>
                <div className='payment-method'>
                    <img src={WeChatPay} alt='WeChat Pay' className='payment-icon'/>
                </div>
            </div>

        </>
    )
}