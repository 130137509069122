import React from 'react';
import '../../App.css';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';
import BillLau from '../../images/Bill Lau.png';
import BBB from '../../images/BBB.png';
import ULC from '../../images/ulc-logo.png';

export default function AboutUs(){
    const { t } = useTranslation();
    return (
        <div>
            <h1 className='about-us'> </h1>
            <br/>
            <div className='information'>
                <div className='about-1-container'>
                    <div className='about-1'>
                        <h2>{t("CBA About 1", {years: (Math.floor((new Date().getFullYear() - 1989)/5) * 5)})}<br/><br/>
                            {t("CBA About 2")}<br/><br/>
                            {t("CBA About 3")}<br/><br/>
                        </h2>
                    </div>
                </div>
                <h2>
                    <a href='./documents/CBA-Ad-1.pdf' target='_blank' rel='noopener noreferrer'>{t("CBA Ad")} 1</a><br/><br/>
                    <a href='./documents/CBA-Ad-2.pdf' target='_blank' rel='noopener noreferrer'>{t("CBA Ad")} 2</a>
                </h2>
                <div className='president'>
                    <img src={BillLau} alt="President Bill Lau" className='president-portrait'></img>
                    <h2>{t("Bill Lau (President)")}</h2>
                </div>
                <div className='certifications'>
                    <div className='certifications-item'>
                        <img src={BBB} alt = "BBB A+ Accredited Business" className='certification-icon'></img>
                    </div>
                    <div className='certifications-item'>
                        <img src={ULC} alt = "ULC Certified" className='certification-icon'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}