import React from 'react';
import CardItem from './CardItem';
import './Cards.css';
import { useTranslation } from 'react-i18next';

function Cards() {
    const { t } = useTranslation();
    return (
        <div className='cards'>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem
                            src='../images/burglar-alarm.jpg'
                            text= {t("Burglary Alarm Text")}
                            label={t("Burglary Alarm")}
                            path='/products-and-services'
                        />
                        <CardItem
                            src='../images/cctv.jpg'
                            text={t("CCTV Text")}
                            label={t("CCTV")}
                            path='/products-and-services'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        src='../images/fire-alarm.jpg'
                        text={t("Fire Alarm Text")}
                        label={t("Fire Alarm")}
                        path='/products-and-services'
                        />
                        <CardItem
                        src='../images/smart-home.jpg'
                        text={t("Smart Home Text")}
                        label={t("Smart Home")}
                        path='/products-and-services'
                        />
                        
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        src='../images/ev-charging.jpg'
                        text={t("Electric Vehicle Charger Installation Text")}
                        label={t("Electric Vehicle Charger Installation")}
                        path='/products-and-services'
                        />
                        <CardItem
                        src='../images/emergency-button.jpg'
                        text={t("Emergency Button Text")}
                        label={t("Emergency Button")}
                        path='/products-and-services'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        src='../images/professional.jpg'
                        text={t("Professional Installation and Repair Text", {years: (Math.floor((new Date().getFullYear() - 1989)/5) * 5)})}
                        label={t("Professional Installation and Repair")}
                        path='/products-and-services'
                        />
                        <CardItem
                        src='../images/monitoring.jpg'
                        text={t("24-Hour ULC Monitoring Text")}
                        label={t("24-Hour ULC Monitoring")}
                        path='/products-and-services'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;