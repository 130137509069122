import { Link } from 'react-router-dom';
import './Banner.css';
import CBALogo from '../images/cba-logo.svg';
import { useTranslation } from 'react-i18next';

function Banner(){

	const { t } = useTranslation();
	return(
		<>
            <div className='banner'>
                <div className='banner-container'>
                    <div className='logo'>
                        <div className='cba-logo-container'>
                            <Link to='/'>
                                <img src={CBALogo} alt='CBA Alarm' className='cba-logo'/>
                            </Link>
                        </div>
                        <div className='company-name'>
                            <h1>{t("CBA Company Name")}</h1>
                        </div>
                    </div>
                    <div className = 'telephone-numbers'>
                        <div className='telephone'>
                            <h1><i className='fas fa-phone' /></h1>
                            <h1><a href="tel:6042631882">(604) 263-1882</a></h1>
                        </div>
                        <div className='telephone'>
                            <h1><i className='fas fa-phone' /></h1>
                            <h1><a href="tel:6042631882">(604) 880-7888</a></h1>
                        </div>
                        <div className='telephone'>
                            <h1><i className='fas fa-fax' /></h1>
                            <h1><a href="tel:6042631882">(604) 263-0887</a></h1>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
}

export default Banner