import React from 'react';
import '../App.css';
import './Showcase.css';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

function Showcase() {
    const { t } = useTranslation();
    
    function scrollToTop(){
		window.scrollTo({top: 0});
	}

    return (
        <div className='showcase-container'>
            <video autoPlay loop muted playsInline>
                <source src="./videos/cba-video.m4v"/>
                <img src="./images/cba-home.jpg" alt={t("CBA Company Name")} title="Your browser does not support the video tag"/>
            </video>
            <h1 className="home">{t("CBA Company Name Short")}</h1>
            <div className="showcase-btns">
                <Button 
                    className='btns' 
                    buttonStyle='btn--primary' 
                    buttonSize='btn--large'
                    path='/products-and-services'
                    onClick={()=> scrollToTop()}
                >
                    {t("Products & Services")}
                </Button>
                <br/>
                <Button 
                    className='btns' 
                    buttonStyle='btn--primary' 
                    buttonSize='btn--large'
                    path='/contact-us'
                    onClick={()=> scrollToTop()}
                >
                    {t("Contact Us")} 
                </Button>
            </div>
        </div>
    )
}

export default Showcase
