import React from 'react';
import {BrowserRouter as Router, Switch, Route } from
'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import ProductsAndServices from './components/pages/Products-And-Services';
import AboutUs from './components/pages/AboutUs';
import Community from './components/pages/Community';
import ContactUs from './components/pages/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {

  return (
    <>
    <Router>
      <Header />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/products-and-services' exact component={ProductsAndServices}/>
        <Route path='/about-us' exact component={AboutUs}/>
        {/**
         * Removed community page because did not want to have company associated with other organizations (people are sensitive)
         * Remove comment to restore
         * 
          <Route path='/community' exact component={Community}/>
         */}
        <Route path='/contact-us' exact component={ContactUs}/>
      </Switch>
      <Footer />
    </Router>
    </>
  );
}

export default App;