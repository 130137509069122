import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import {Button} from './Button';
import { useTranslation } from 'react-i18next';

function Navbar(){
	const [click, setClick] = useState(false);

	const toggleMenu = () => setClick(!click);
	const closeMobileMenu = () => {
		window.scrollTo({top: 0});
		setClick(false);
	}

	const { t } = useTranslation();

	const { i18n } = useTranslation();
	function pickLanguage(lang){
		window.scrollTo({top: 0});
		i18n.changeLanguage(lang);
	}

	return(
		<>
			<nav className='navbar'>
				<div className='navbar-container'>
					<div className='menu-icon' onClick={toggleMenu} style={{whiteSpace: 'nowrap'}}>
						{t("Menu")}
						<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
					</div>
					<ul className={click ? 'nav-menu active' : 'nav-menu'}>
						<li className='nav-item'>
							<Link to='/' className='nav-links' onClick={closeMobileMenu}>
								{t("Home")}
							</Link>
						</li>
						<li className='nav-item'>
							<Link to='/products-and-services' className='nav-links' onClick={closeMobileMenu}>
								{t("Products & Services")}
							</Link>
						</li>
						<li className='nav-item'>
							<Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
								{t("About Us")}
							</Link>
						</li>
						{/**
						 * Removed community page because did not want to have company associated with other organizations (people are sensitive)
         				 * Remove comment to restore
						 	<li className='nav-item'>
								<Link to='/community' className='nav-links' onClick={closeMobileMenu}>
									{t("Community")}
								</Link>
							</li>
						 */}
						
						<li className='nav-item'>
							<Link to='/contact-us' className='nav-links' onClick={closeMobileMenu}>
								{t("Contact Us")}
							</Link>
						</li>
						<li className='nav-item'>
							<div className='language-buttons'>
								<div className = 'nav-item-button'>
									<Button path= "/" buttonStyle='btn--outline' onClick={()=>pickLanguage('en')}>English</Button>
								</div>
								<div className = 'nav-item-button'>
									<Button path= "/" buttonStyle='btn--outline' onClick={()=>pickLanguage('chi_s')}>简体中文</Button>
								</div>
								<div className = 'nav-item-button'>
									<Button path= "/" buttonStyle='btn--outline' onClick={()=>pickLanguage('chi_t')}>繁體中文</Button>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
}

export default Navbar