import React from 'react'
import './Header.css';
import Navbar from './Navbar';
import Banner from './Banner';

function Header() {
    return (
        <div className='header'>
                <Banner/>
                <Navbar/>
        </div>
    );
}

export default Header
